import React, { useEffect } from 'react'
import HeroSection from '../../Components/home/HeroSection'
import OurCategories from '../../Components/home/OurCategories'
import OurServices from '../../Components/home/OurServices'
import GetTouch from '../../Components/home/GetTouch'
import GallerySection from '../../Components/home/GallerySection'
import Testimonial from '../../Components/home/Testimonial'
import OurTeam from '../../Components/home/OurTeam'
import BestPlans from '../../Components/aboutus/BestPlans'
import Aos from 'aos'
import 'aos/dist/aos.css'

const Home = () => {
  useEffect(() => {
    Aos.init({
      duration: 1200,  
      offset: 200,    
      once: true,
    });

  }, [])
  return (
    <div className='overflow-x-hidden'>
      <HeroSection />
      <OurCategories />
      <OurServices />
      <BestPlans />
      <GallerySection />
      <Testimonial />
      <OurTeam />
      <GetTouch />
    </div>
  )
}

export default Home