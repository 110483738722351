import React from 'react'
import img1 from '../../Assets/homeimg/address 1.png'
import img2 from '../../Assets/homeimg/Email.png'
import img3 from '../../Assets/homeimg/Phone.png'
const GetTouch = () => {
    return (
        <div className="mx-auto py-12 ">
            <h1  style={{ fontFamily: "Cinzel, serif" }} className="text-4xl font-bold text-center text-[#4E2728]">Contact <span className="text-black">Us</span></h1>
            <p className="text-center text-gray-600 mt-4 mx-6">We'd Love To Hear From You! Whether You're Looking To Book An Appointment Or Have Questions About Our Services, Feel Free To Reach Out To Us.</p>

            <div className="flex flex-col lg:flex-row mt-12 md:mx-16">
                <div className="lg:w-1/3 bg-[#4E2728] p-8 text-white mb-8 lg:mb-0 rounded-md">
                    <div className="mb-8">
                        <img src={img1} alt='img'/>
                        <p className="mt-4">Hotel Sandy Tower, XIMB Square, Nandankanan Rd, Gajapati Nagar, Bhubaneswar, Odisha 751013</p>
                    </div>
                    <div className="mb-8">
                        <img src={img2} alt='img'/>
                        <p className="mt-4">Kaliraspa2021@Gmail.Com</p>
                    </div>
                    <div className="mb-8">
                        <img src={img3} alt='img'/>
                        <p className="mt-4">+91 8114882165</p>
                    </div>
                    <div>
                        <h2 className="text-xl font-bold">OPENING HOURS</h2>
                        <p className="mt-4">Monday- Sunday</p>
                        <p>9:00 - 10:00 PM</p>
                    </div>
                </div>
                <div className="lg:w-2/3 bg-yellow-100 p-8">
                    <h2 className="text-2xl font-bold mb-8">Get In Touch</h2>
                    <form>
                        <div className="mb-4">
                            <label className="block mb-2" htmlFor="name"><i className="fas fa-user"></i> Name</label>
                            <input className="w-full p-2 border border-gray-300 rounded" type="text" id="name" placeholder="Name" />
                        </div>
                        <div className="mb-4">
                            <label className="block mb-2" htmlFor="email"><i className="fas fa-envelope"></i> Email</label>
                            <input className="w-full p-2 border border-gray-300 rounded" type="email" id="email" placeholder="Email" />
                        </div>
                        <div className="mb-4">
                            <label className="block mb-2" htmlFor="phone"><i className="fas fa-phone"></i> Phone</label>
                            <input className="w-full p-2 border border-gray-300 rounded" type="text" id="phone" placeholder="Phone" />
                        </div>
                        <div className="mb-4">
                            <label className="block mb-2" htmlFor="message"><i className="fas fa-comment"></i> Message</label>
                            <textarea className="w-full p-2 border border-gray-300 rounded" id="message" placeholder="Message"></textarea>
                        </div>
                        <button className="bg-[#4E2728] text-white px-4 py-2 rounded" type="submit">Submit</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default GetTouch