import React, { useEffect, useState } from "react";
import image from "../../Assets/homeimg/heroimg.png";
import smallimage from "../../Assets/homeimg/smallheroimg.png";

import BookModal from "./BookModal";
import axios from "axios"

const HeroSection = () => {
  const [open, setOpen] = useState(false);
  const [heroSectionData, setHeroSectionData] = useState();

  const showHeroSection = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/heroSection/getHeroSection/674586c19aa6780fb268867d`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Response : ", response.data);
        setHeroSectionData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleAddOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    showHeroSection();
  }, []);
  return (
    <React.Fragment>
      <div
        className="hidden md:block md:py-10 h-[85vh] text-center"
        style={{
          backgroundImage: `url(${heroSectionData?.[0]?.bgImg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right",
        }}
      >
        <div className="text-start text-black p-8 max-w-7xl z-10 md:w-1/2 md:space-y-12">
          <h1
            style={{ fontFamily: "Cinzel, serif" }}
            className="text-3xl md:text-7xl  mb-4 tracking-wider text-center lg:text-start md:mt-0 mt-10 font-bold md:font-medium"
          >
            {/* Best Place for Natural SPA Treatment */}
            {heroSectionData?.[0]?.heading}
          </h1>
          <p className="text-base md:text-lg mb-6 tracking-wide text-center lg:text-start font-bold md:font-normal">
            {/* Discover Your Signature Style With Our Expert Team: Personalized
            Beauty Journeys In A Luxurious Haven - Where Innovation Meets
            Tradition In */}
            {heroSectionData?.[0]?.subheading}
            <br />
            The Art Of Beauty.
          </p>
          {/* <button
                        className="bg-[#FFC700] text-white font-bold py-2 px-6 rounded-full shadow-lg hover:bg-yellow-600"
                        onClick={handleAddOpen}
                    >
                        Book Now
                    </button> */}
          <button
            className="mt-4 bg-[#4E2728] text-white font-bold py-2 px-4 rounded shadow hover:bg-[#aa585a]"
            onClick={handleAddOpen}
          >
            Book Now
          </button>
        </div>
        {open && <BookModal onClose={handleModalClose} />}
      </div>

      <div
        className="block md:hidden md:py-10 h-screen text-center"
        style={{
          backgroundImage: `url(${heroSectionData?.[0]?.bgImg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right",
        }}
      >
        <div className="text-start text-black p-8 max-w-7xl z-10 md:w-1/2 md:space-y-12">
          <h1
            style={{ fontFamily: "Cinzel, serif" }}
            className="text-3xl md:text-7xl  mb-4 tracking-wider text-center lg:text-start md:mt-0 mt-10 font-bold md:font-medium"
          >
            Best Place for Natural SPA Treatment
          </h1>
          <p className="text-base md:text-lg mb-6 tracking-wide text-center lg:text-start font-bold md:font-normal">
            Discover Your Signature Style With Our Expert Team: Personalized
            Beauty Journeys In A Luxurious Haven - Where Innovation Meets
            Tradition In
            <br />
            The Art Of Beauty.
          </p>
          <div className="flex justify-center">
            <button
              className="bg-[#FFC700] text-white font-bold py-2 px-6 rounded-full shadow-lg hover:bg-[#aa585a]"
              onClick={handleAddOpen}
            >
              Book Now
            </button>
          </div>
        </div>
        {open && <BookModal onClose={handleModalClose} />}
      </div>
    </React.Fragment>
  );
};

export default HeroSection;
