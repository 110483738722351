import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles

import icons1 from '../../Assets/homeimg/Body.png';
import icons2 from '../../Assets/homeimg/Facial.png';
import icons3 from '../../Assets/homeimg/message.png';
import icons4 from '../../Assets/homeimg/Prenatal.png';
import icons6 from '../../Assets/homeimg/Specialized.png';
import img1 from '../../Assets/homeimg/Bodyimg.png';
import img2 from '../../Assets/homeimg/Facialimg.png';
import img3 from '../../Assets/homeimg/messageimg.png';
import img4 from '../../Assets/homeimg/Prenatalimg.png';
import img5 from '../../Assets/homeimg/Wellnessimg.png';
import img6 from '../../Assets/homeimg/Specializedimg.png';

function CategoryCard({ icon, title, description, imageUrl, altText, aosType }) {
    return (
        <div
            className="bg-[#FFF9E5] shadow-lg overflow-hidden transition-transform duration-300 transform hover:scale-105 hover:shadow-xl"
            data-aos={aosType}
        >
            <div className="text-center">
                <div className="relative flex flex-col items-center px-10 p-4">
                    <img src={icon} alt="icons" className="mb-2 text=[#4E2728]" />
                    <h2 className="text-2xl font-bold mb-2">{title}</h2>
                    <p className="text-gray-600 mb-4 tracking-wide">{description}</p>
                    <div className="absolute -bottom-20 bg-[#4E2728] p-4 text-center rounded-full w-20 h-20 flex items-center justify-center font-bold text-white">
                        30 MINS
                    </div>
                </div>
                <div className="mt-14">
                    <img src={imageUrl} alt={altText} className="w-full object-cover" />
                </div>
            </div>
        </div>
    );
}

const OurCategories = () => {
    useEffect(() => {
        Aos.init({
            duration: 1200,  
            offset: 200,    
            once: false,
          });
    }, []);

    const categories = [
        {
            icon: icons1,
            title: "Body Treatments",
            description: "Enhance skin health through exfoliation, wraps, and hydration for full-body rejuvenation.",
            imageUrl: img1,
            altText: "Person receiving a body treatment massage",
            aosType: 'fade-up',
        },
        {
            icon: icons2,
            title: "Facial",
            description: "Skincare treatments to cleanse, exfoliate, and nourish the face for a glowing complexion.",
            imageUrl: img2,
            altText: "Person receiving a facial treatment",
            aosType: 'fade-right',
        },
        {
            icon: icons3,
            title: "Massage",
            description: "Therapeutic techniques to relax muscles, reduce stress, and improve circulation.",
            imageUrl: img3,
            altText: "Person receiving a massage",
            aosType: 'fade-left',
        },
        {
            icon: icons4,
            title: "Prenatal Massage",
            description: "Prenatal massage alleviates pregnancy discomfort, reduces stress, and promotes relaxation for mothers-to-be.",
            imageUrl: img4,
            altText: "Person receiving a body treatment massage",
            aosType: 'fade-up',
        },
        {
            icon: icons2,
            title: "Facial Wellness",
            description: "Facial wellness enhances skin health, promotes relaxation, and rejuvenates the face and neck. nd rejuvenates the face and neck.",
            imageUrl: img5,
            altText: "Person receiving a facial treatment",
            aosType: 'zoom-in', 
        },
        {
            icon: icons6,
            title: "Specialized Massage",
            description: "Specialized massage targets individual needs, relieving pain and tension through customized techniques and approaches.",
            imageUrl: img6,
            altText: "Person receiving a massage",
            aosType: 'flip-left', 
        },
    ];

    return (
        <div className="mx-6 md:mx-16 py-12">
            <h1 
             style={{ fontFamily: "Cinzel, serif" }}
            className="text-4xl font-bold text-center mb-12">Our Categories</h1>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
                {categories.map((category, index) => (
                    <CategoryCard key={index} {...category} />
                ))}
            </div>
        </div>
    );
};

export default OurCategories;
