import { React, useState, useEffect } from 'react';
import image from '../../Assets/homeimg/Testimonialbgimg.png';
import { BiLeftArrow } from 'react-icons/bi'; 
import { BiRightArrow } from 'react-icons/bi';
import axios from "axios";

const Testimonial = () => {
  const [reviews, setReviews] = useState([]);
  const fetchReviews = async () => {
    let config = {
      method: "get",
      maxBodyLenght: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/reviews/fetchReviews?PLACE_ID=ChIJc6WIPZAJGToR5IppCrx1wkc&API_KEY=XYZ`,
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Response---->", response.data);
        setReviews(response.data);
      })
      .catch((error) => {
        console.log("Error in fetching reviews", error);
      });
  };

  useEffect(() => {
    fetchReviews();
  }, []);
    return (
        <div className="mt-10 relative p-9" 
            style={{
                backgroundImage: `url(${image})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
            }}
        >
            <button className="hidden md:block absolute md:left-32 md:top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md hover:bg-gray-200 transition">
                <BiLeftArrow className="w-6 h-6 text-gray-700" />
            </button>

           
            <button className="hidden md:block absolute md:right-32 md:top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md hover:bg-gray-200 transition">
                <BiRightArrow className="w-6 h-6 text-gray-700" />
            </button>

            <div className="text-center mx-auto px-4 py-10 md:py-20">
                <p className="text-gray-700 text-base sm:text-lg lg:text-xl xl:text-2xl mb-6 max-w-3xl mx-auto">
                    "I Had An Incredible Experience At [Spa Name]. From The Moment I Walked In, The Atmosphere Was Calm And Soothing. The Massage Therapist Was Highly Skilled And Attentive To My Needs, Providing A Perfect Balance Of Relaxation And Muscle Relief. I Left Feeling Refreshed, Rejuvenated, And Stress-Free. The Entire Experience Was Pure Bliss, And I Can’t Wait To Return!"
                </p>
                <div className="flex items-center justify-center">
                    <img
                        src="https://storage.googleapis.com/a1aa/image/Nki2Hni2WjJsLFuOsBmKEbxXkRs5fFQeLqf4fvSYSM6IPe0cC.jpg"
                        alt="Portrait of Ditya Mishra"
                        className="w-16 h-16 sm:w-20 sm:h-20 lg:w-24 lg:h-24 xl:w-28 xl:h-28 rounded-full border-2"
                    />
                </div>
                <div className="mt-4">
                    <p className="text-[#aa585a] text-lg sm:text-xl lg:text-2xl font-bold">Ditya Mishra</p>
                    <p className="text-gray-500 text-sm sm:text-base lg:text-lg">Massage Specialist</p>
                </div>
            </div>
        </div>
    );
};

export default Testimonial;