import React, { useState, useEffect } from "react";
import img1 from "../../Assets/homeimg/Galleryimg1.png";
import img2 from "../../Assets/homeimg/Galleryimg2.png";
import img3 from "../../Assets/homeimg/Galleryimg3.png";
import img4 from "../../Assets/homeimg/Galleryimg4.png";
import img5 from "../../Assets/homeimg/Galleryimg5.png";
import img6 from "../../Assets/homeimg/Galleryimg6.png";
import Aos from "aos";
import "aos/dist/aos.css";
import axios from "axios"

const GallerySection = () => {
  //State to store all the gallery photos of a branch
  const [allGallery, setAllGallery] = useState([]);

  //Function to fetch all the gallery photos of a branch
  const showAllgalleryData = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_KEY}/gallery/getGallery/674586c19aa6780fb268867d`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Response---->", response.data);
        setAllGallery(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  console.log("Gallery----->", allGallery);

  useEffect(() => {
    showAllgalleryData();
  }, []);

  useEffect(() => {
    Aos.init({
      duration: 1200,
      offset: 200,
      once: false,
    });
  }, []);
  return (
    <>
      <div className="text-center mb-8">
        <p className="text-[#4E2728">Gallery</p>
        <h1
          style={{ fontFamily: "Cinzel, serif" }}
          className="text-3xl font-bold "
        >
          Our Latest Works
        </h1>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10 mx-6 md:mx-16">
        <img
          src={allGallery[0]?.gallery}
          alt="Person receiving a back massage"
          data-aos="fade-right"
          className="rounded-[1rem] object-cover h-full w-full"
        />
        <img
          src={allGallery[1]?.gallery}
          alt="Person receiving a facial massage"
          data-aos="fade-up"
          className="rounded-[1rem] object-cover h-full w-full"
        />
        <img
          src={allGallery[2]?.gallery}
          alt="Person receiving a shoulder massage"
          data-aos="fade-left"
          className="rounded-[1rem] object-cover h-full w-full"
        />
        <img
          src={allGallery[3]?.gallery}
          alt="Person receiving a back massage with a therapist"
          data-aos="fade-up"
          className="rounded-[1rem] object-cover h-full w-full"
        />
        <img
          src={allGallery[4]?.gallery}
          alt="Spa items including candles, a towel, and a small plant"
          data-aos="fade-up"
          className="rounded-[1rem] object-cover h-full w-full"
        />
        <img
          src={allGallery[5]?.gallery}
          alt="Person receiving a back massage in a spa setting"
          data-aos="fade-left"
          className="rounded-[1rem] object-cover h-full"
        />
      </div>
    </>
  );
};

export default GallerySection;
